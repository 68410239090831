import axios from 'axios';

export const axiosAuth = axios.create({
    baseURL: 'https://api.dyorex.com',
    headers: {
        platform: "WEB",
    },
})


axiosAuth.interceptors.request.use(
    config => {
        // console.log('config', config.baseURL, config.url, config.params)
        return config
    }
)