import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Logo from '../../assets/images/svg/footer-logo.svg';
import { ExternalLink } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import AppStore from '../../assets/images/svg/appStore.svg';
import GooglePlay from '../../assets/images/svg/googlePlay.svg';

const Footer = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const menuItems = [
        {
            id: 1,
            title: t('What is DYR Token?'),
            path: '/#what-is-dyr-token',
        },
        {
            id: 2,
            title: t('Why DYR?'),
            path: '/#why-dyr',
        },
        { id: 4, title: t('FAQ'), path: '/#faq' },
        { id: 3, title: t('Contact'), path: '/#contact-form' },
    ];

    const scrollToHash = (hash) => {
        if (location.pathname === '/') {
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element) {
                    const navbarHeight = 85;
                    const elementPosition = element.offsetTop - navbarHeight;
                    window.scrollTo({
                        top: elementPosition,
                        behavior: 'smooth'
                    });
                }
            }, 0);
        }
    };

    return (
        <div className='bg-[#F8F8F8] py-[82px]'>
            <div className='max-w-[1920px] mx-auto px-5'>
                <div className='flex lg:flex-row  flex-col lg:justify-around justify-center gap-8'>
                    {/* Logo ve Website Link Bölümü */}
                    <div className='flex flex-col gap-4'>
                        <img src={Logo} alt="DYR Token" width={140} height={44} />
                        <a
                            href="https://www.dyorex.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className='flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors'
                        >
                            <span className='text-base'>dyorex.com</span>
                            <ExternalLink size={16} />
                        </a>
                        {/* Menu Items */}
                        <div className='flex lg:flex-row flex-col gap-6'>
                            {menuItems.map((item) => (
                                <Link
                                    key={item.id}
                                    to={item.path}
                                    onClick={() => scrollToHash(item.path.substring(1))}
                                    className='text-[#525252] hover:text-gray-900 transition-colors'
                                >
                                    {item.title}
                                </Link>
                            ))}
                        </div>
                        <p className='text-[#868686] text-sm'>{t('copyright')}</p>
                    </div>


                    {/* download */}

                    <div className='flex flex-col gap-2'>
                        <p className='text-[#7B6464] text-lg'>{t('Download DyorEX')}</p>
                        <a href="https://apps.apple.com/tr/app/dyorex-exchange/id1602952926"> <img src={AppStore} alt="appStore" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.dyorex.dyorex_mobile_app&hl=tr&gl=US&pli=1"><img src={GooglePlay} alt="googlePlay" /></a>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Footer;