import { axiosAuth } from '../config/axios'

export const getNews = async () => {
    try {
        const result = await axiosAuth.get('/academy/api/contents?categoryId=8&pageNumber=1&pageSize=3&sortOrder=DESC')

        return { data: result?.data.data }
    } catch (error) {
        console.error('news')
        console.error(error)
    }
}