import React, { useState } from 'react';
import { ChevronDown, Plus, ArrowDown, ChevronsDown, X } from 'lucide-react';

const icons = {
    chevron: {
        closed: ChevronDown,
        open: ChevronDown
    },
    plus: {
        closed: Plus,
        open: X
    },
    arrow: {
        closed: ArrowDown,
        open: ArrowDown
    },
    doubleChevron: {
        closed: ChevronsDown,
        open: ChevronsDown
    }
};

const renderContent = (content, type = 'text') => {
    switch (type) {
        case 'list':
            return (
                <ul className="list-disc list-inside space-y-2 overflow-y-auto">
                    {content.map((item, index) => (
                        <li key={index} className="text-[#3C3C43D9]">{item}</li>
                    ))}
                </ul>
            );

        case 'numbered':
            return (
                <ol className="list-decimal list-inside space-y-2">
                    {content.map((item, index) => (
                        <li key={index} className="text-[#3C3C43D9]">{item}</li>
                    ))}
                </ol>
            );

        case 'custom':
            return content;

        case 'text':
        default:
            return <p className="text-[#3C3C43D9]">{content}</p>;
    }
};

const formatNumber = (num) => {
    return num < 10 ? `0${num}` : num.toString();
};

const Accordion = ({ items, iconType = 'chevron', iconClassName = '' }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleItem = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const iconSet = icons[iconType] || icons.chevron;

    return (
        <div className="w-full mx-auto border rounded-lg divide-y">
            {items.map((item, index) => {
                const isOpen = openIndex === index;
                const Icon = isOpen ? iconSet.open : iconSet.closed;
                const questionNumber = formatNumber(index + 1);

                return (
                    <div
                        key={index}
                        className="overflow-hidden"
                    >
                        <button
                            onClick={() => toggleItem(index)}
                            className={`w-full flex flex-wrap items-start gap-4 p-4 bg-white hover:bg-gray-50 transition-colors ${
                                isOpen ? 'bg-gray-50' : ''
                            }`}
                        >
                            <div className="flex flex-1 items-center gap-5 min-w-0">
                                <span className="lg:text-5xl text-sm font-bold text-[#3C3C4380] shrink-0">{questionNumber}</span>
                                <span className="font-bold lg:text-xl text-sm text-gray-900 text-left break-words">{item.title}</span>
                            </div>
                            <div className={`rounded-full p-2 transition-colors duration-200 shrink-0 ${
                                isOpen 
                                    ? 'bg-gray-900 text-white' 
                                    : 'bg-gray-100 text-gray-500 hover:bg-gray-200'
                            }`}>
                                <Icon
                                    className={`w-5 h-5 transition-transform duration-200 ${
                                        iconType !== 'plus' && isOpen ? 'rotate-180' : ''
                                    } ${iconClassName}`}
                                />
                            </div>
                        </button>

                        <div
                            className={`overflow-y-auto transition-all duration-200 ${
                                isOpen ? 'max-h-96' : 'max-h-0'
                            }`}
                        >
                            <div className="p-4 bg-gray-50 border-t">
                                {renderContent(item.content, item.contentType)}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Accordion;