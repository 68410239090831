import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Play } from 'lucide-react';
import { useTranslation } from 'react-i18next';
// import Button from './Button';
import Video from '../assets/gif/video.mp4'
const Hero = () => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(true);
    const { t } = useTranslation();
    const words = [t('Speed'), t('Trust'), t('Productivity')];

    useEffect(() => {
        const interval = setInterval(() => {
            setIsAnimating(false);
            setTimeout(() => {
                setCurrentWordIndex((prev) => (prev + 1) % words.length);
                setIsAnimating(true);
            }, 200);
        }, 2000);

        return () => clearInterval(interval);
    }, [words.length]);

    return (
        <div className="lg:w-[880px] lg:py-[150px] py-[100px] mx-auto relative px-5">
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    zIndex: -1
                }}
            >
                <video autoPlay loop muted playsInline style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                }}>
                    <source src={Video} type="video/mp4" />
                </video>
            </div>
            <div className="text-white p-8 rounded-[24px]  shadow-xl bg-gradient-to-b from-[#0000005C] to-[#00000011] backdrop-blur lg:w-[807px] ">
                <h1 className="lg:text-6xl text-3xl font-normal mb-8">{t('In the Crypto World')}</h1>

                <div className="h-16 overflow-hidden relative mb-8">
                    <div
                        className={`text-[#DA2C38] lg:text-6xl text-3xl font-bold transition-transform duration-200 ${isAnimating ? 'transform translate-y-0' : 'transform -translate-y-full'
                            }`}
                    >
                        {words[currentWordIndex]}
                    </div>
                </div>

                <p className="lg:text-2xl text-lg mb-8 break-words">
                    {t('subTitle')}
                </p>

                <div className="flex lg:flex-row flex-col gap-4">
                    <Link className="text-center bg-gradient-to-b from-[#D21E2B] to-[#D27077] hover:bg-red-600 text-white px-6 py-2 rounded-full transition-colors duration-300 lg:w-auto w-[185px]"
                        to={'https://www.dyorex.com/exchange/ex/trade/advanced/DYR-TRY'}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('buyNow')}
                    </Link>
                    <Link className="flex items-center justify-center border border-white text-white px-6 py-2 rounded-full hover:bg-white/10 transition-colors duration-300 lg:w-auto w-[185px]"
                        to={'https://www.youtube.com/@dyorexcom'}
                        target="_blank"
                        rel="noreferrer"

                    >
                        <Play size={24} className="mr-2" />
                        <span>{t('watchTrailer')}</span>

                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Hero