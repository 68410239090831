import { useState, useEffect } from 'react'
import Section from '../components/Section'
import LowNetworkFee from '../assets/images/svg/low-network-fee.svg'
import Speed from '../assets/images/svg/speed.svg'
import Balance from '../assets/images/svg/balance-scale.svg'
import Secure from '../assets/images/svg/secure.svg'
import { useTranslation } from 'react-i18next'

import FacebookIcon from '../assets/images/svg/facebook.svg'
import LinkedInIcon from '../assets/images/svg/linkedin.svg'
import WhatsAppIcon from '../assets/images/svg/wp.svg'
import YoutubeIcon from '../assets/images/svg/youtube.svg'
import XIcon from '../assets/images/svg/x.svg'
import TelegramIcon from '../assets/images/svg/telegram.svg'
import InstagramIcon from '../assets/images/svg/instagram.svg'
import Accordion from '../components/Accordion'
import Contact from '../components/Contact'
// import DyrTokenBg from '../assets/images/img/dyr token.jpg'
import Video from '../assets/gif/video.mp4'
import { getNews } from '../services/academy'
import moment from 'moment'
import { Link } from 'react-router-dom'

const URL_NEWS = 'https://www.dyorex.com/academy/bulletin/'

const Home = () => {
    const { t } = useTranslation()
    const [newsData, setNewsData] = useState([])

    const cards = [
        {
            id: 1,
            title: t('Low Network Fee'),
            description: t('Low network fees on all cryptocurrency transactions.'),
            image: LowNetworkFee
        },
        {
            id: 2,
            title: t('Speed'),
            description: t('Create a block every 5 seconds with minimum waiting time for transactions.'),
            image: Speed
        },
        {
            id: 3,
            title: t('Scalability'),
            description: t('Perform more transactions in each block thanks to large block sizes.'),
            image: Balance
        },
        {
            id: 4,
            title: t('Security'),
            description: t('Conduct secure transactions with the latest and most advanced encryption standards.'),
            image: Secure
        }
    ]

    const socialMedia = [
        {
            icon: InstagramIcon,
            url: 'https://www.instagram.com/dyorexcom/',
            label: 'Instagram account link',
        },
        {
            icon: FacebookIcon,
            url: 'https://www.instagram.com/dyorexcom/',
            label: 'Facebook account link',
        },
        {
            icon: XIcon,
            url: 'https://twitter.com/dyorexcom',
            label: 'Twitter account link',
        },
        {
            icon: LinkedInIcon,
            url: 'https://www.linkedin.com/company/dyorexcom/',
            label: 'LinkedIn account link',
        },
        {
            icon: YoutubeIcon,
            url: 'https://www.youtube.com/channel/UCRa9XU1sdPeUb3Y9vZ94IBA',
            label: 'Youtube account link',
        },

        {
            icon: TelegramIcon,
            url: 'https://t.me/dyorexdestek',
            label: 'Telegram account link',
        },
        {
            icon: WhatsAppIcon,
            url: 'https://wa.me/908505323976',

            label: 'WhatsApp account link',
        },
    ]

    const accordionItems = [
        {
            id: 1,
            title: t('What is DYR Token?'),
            content: t("accordion1.content")
        },
        {
            id: 2,
            title: t('accordion2.title'),
            content: (

                <div className="flex space-x-2 space-y-4">

                    <ul className='list-inside space-y-2 text-[#3C3C43D9]'>
                        <li>{t('accordion2.content')}</li>
                        <li>{t('accordion2.content2')}</li>
                        <li>{t('accordion2.content3')}</li>
                        <li>{t('accordion2.content4')}</li>
                        <li>{t('accordion2.content5')}</li>
                        <li>{t('accordion2.content6')}</li>
                        <li>{t('accordion2.content7')}</li>
                    </ul>
                </div>

            ),
            contentType: 'custom'
        },
        {
            id: 3,
            title: t('accordion3.title'),
            content: (

                <div className="flex space-x-2 space-y-4">

                    <ul className='list-inside space-y-2 text-[#3C3C43D9]'>
                        <li>{t('accordion3.content')}</li>
                        <li>{t('accordion3.content2')}</li>
                        <li>{t('accordion3.content3')}</li>
                        <li>{t('accordion3.content4')}</li>
                        <li>{t('accordion3.content5')}</li>
                        <li>{t('accordion3.content6')}</li>
                        <li>{t('accordion3.content7')}</li>
                    </ul>
                </div>

            ),
            contentType: 'custom'
        },
        {
            id: 4,
            title: t('accordion4.title'),
            content: (

                <div className="flex space-x-2 space-y-4">

                    <ul className='list-inside space-y-2 text-[#3C3C43D9]'>
                        <li>{t('accordion4.content')}</li>
                        <li>{t('accordion4.content2')}</li>
                        <li>{t('accordion4.content3')}</li>
                        <li>{t('accordion4.content4')}</li>
                        <li>{t('accordion4.content5')}</li>
                        <li>{t('accordion4.content6')}</li>
                        <li>{t('accordion4.content7')}</li>
                    </ul>
                </div>

            ),
            contentType: 'custom'
        },

    ]

    const handleNews = async () => {
        try {
            const result = await getNews()
            if (result?.data) {
                setNewsData(result?.data)
                // console.log(result?.data)
            } else {
                console.error("No data found");
            }
        } catch (error) {
            console.error('Error fetching news:', error)
        }
    }

    useEffect(() => {
        handleNews()
    }, [])

    return (
        <>

            {/* what dyr desktop */}
            <Section className='lg:block hidden' style={{
                position: 'relative'
            }}>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        zIndex: -1
                    }}
                >
                    <video autoPlay loop muted playsInline style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                    }}>
                        <source src={Video} type="video/mp4" />
                    </video>
                </div>
            </Section>
            <Section className='flex bg-[#F3F3F3] px-3 w-full' id={"what-is-dyr-token"}>
                <div className='what-dyr xl:w-[1200px] w-full mx-auto rounded-3xl drop-shadow-redShadow mt-[-75px] content-end'>
                    <div className='lg:py-20 py-10'>
                        <div className='lg:w-[584px] w-full px-7 flex flex-col gap-6'>
                            <h3 className='text-white lg:text-5xl text-2xl font-semibold'>{t('What is DYR Token?')}</h3>
                            <p className='text-white'>
                                {t('whatisDyrContent')}
                            </p>
                        </div>
                    </div>
                </div>
            </Section>
            <Section id={'why-dyr'} className=''>
                <div className="py-[94px] bg-[#F3F3F3]  mb-10 ">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-col items-center text-center mb-12">
                            <h3 className="text-3xl font-normal mb-4">
                                {t('Why DYR Token?')}
                            </h3>
                            <p className="text-lg text-gray-600">
                                {t('Advantages and features provided by the DYR Token in the cryptocurrency ecosystem.')}
                            </p>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {cards.map((card) => (
                                <div
                                    key={card.id}
                                    className="bg-white rounded-lg p-6 xl:w-[300px] flex flex-col items-start hover:shadow-lg transition-shadow duration-300"
                                >
                                    <img
                                        src={card.image}
                                        alt={card.title}
                                        className="w-[72px] h-[72px] mb-4"
                                    />
                                    <h4 className="text-2xl text-black font-semibold mb-2">
                                        {card.title}
                                    </h4>
                                    <p className="text-lg text-[#606060]">
                                        {card.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Section >



            {/* news */}
            <Section Section >
                <div className="max-w-[1200px] w-full mx-auto px-4 mb-10">
                    <div className="mb-2 flex flex-col  lg:items-start items-center">
                        <h3 className="text-4xl md:text-[40px] leading-tight text-black">
                            {t('News')}
                        </h3>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {newsData.map((item) => (
                            <Link to={`${URL_NEWS}/${item?.seoLink}`} key={item.id} target='_blank'>
                                <div
                                    key={item.id}
                                    className="flex flex-col gap-4 group hover:shadow-lg transition-shadow duration-300 rounded-lg"
                                >
                                    <div className="overflow-hidden rounded-[30px]">
                                        <img
                                            src={item?.mainImage}
                                            alt={item?.name}
                                            className="w-full h-[220px] object-cover transition-transform duration-300 group-hover:scale-105"
                                        />
                                    </div>

                                    <div className='p-2 space-y-2'>
                                        <span className="text-[#9197A2] text-sm">
                                            {moment(item?.createdOn).format('DD.MM.YYYY - HH:mm:ss')}
                                        </span>

                                        <h3 className="text-xl text-black font-medium line-clamp-2 hover:text-blue-600 transition-colors">
                                            {item?.name}
                                        </h3>

                                        <p className="text-lg text-[#81848B] line-clamp-3">
                                            {item?.description}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </Section >

            {/* com */}

            <Section>
                <div className='community mb-10'>
                    <div className='flex flex-col justify-center items-center py-36 lg:w-[510px] mx-auto text-center gap-[30px]'>
                        <h3 className='text-5xl text-[#D21E2B] font-semibold'>
                            {t('Join the DyorEX Community!')}
                        </h3>
                        <p className='text-xl text-[#656565]'>
                            {t("Follow DyorEX's social media accounts to stay updated on opportunities and news related to DYR Token!")}
                        </p>
                        <div>
                            {
                                socialMedia.map((item, index) => (
                                    <a
                                        key={index}
                                        href={item.url}
                                        target="_blank"
                                        rel="noreferrer"
                                        className='inline-block mx-2'
                                    >
                                        <img
                                            src={item.icon}
                                            alt={item.label}
                                            className='w-[20px] h-[20px] hover:opacity-70 transition-opacity duration-300'
                                        />
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </Section>

            {/* S.S.S */}
            <Section id={'faq'}>
                <div className='mb-20'>
                    <div className='flex flex-col justify-center items-center gap-3 mb-10'>
                        <h3 className='text-5xl text-center'>{t('Frequently Asked Questions about DYR Token')}</h3>
                        <p className='text-2xl text-[#656565] text-center'>{t('Frequently Asked Questions by cryptocurrency investors about DYR Token')}</p>
                    </div>

                    <div className='max-w-[1280px] mx-auto bg-[#F3F5F6] lg:p-8 p-3 rounded-[20px]'>
                        <Accordion items={accordionItems} iconType='plus' />
                    </div>
                </div>
            </Section>

            {/* contact */}

            <Section id={'contact'}>
                <Contact />
            </Section>




        </>

    )
}

export default Home