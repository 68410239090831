import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-[11em] font-bold mb-4">404</h1>
      <p className="text-xl mb-4">{t('Page Not Found')}</p>
      <Link to="/" className="text-blue-500 hover:text-blue-700">
        {t('Return to Home Page')}
      </Link>
    </div>
  );
};

export default NotFound;