import React, { useState, useEffect, useCallback } from 'react';
import { Menu as Menus, X, ChevronDown, Globe } from 'lucide-react';
import Logo from '../../assets/images/svg/logo.svg';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLanguageOpen, setIsLanguageOpen] = useState(false);
    const location = useLocation();
    const [t, i18n] = useTranslation();

    const languages = [
        { code: 'en', name: 'English' },
        { code: 'tr', name: 'Türkçe' }
    ];

    const menuItems = [
        {
            id: 1,
            title: t('What is DYR Token?'),
            path: '/#what-is-dyr-token',
        },
        {
            id: 2,
            title: t('Why DYR?'),
            path: '/#why-dyr',
        },
        { id: 4, title: t('FAQ'), path: '/#faq' },
        { id: 3, title: t('Contact'), path: '/#contact-form' },
    ];

    const scrollToHash = useCallback((hash) => {
        setIsOpen(false);

        if (location.pathname === '/') {
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element) {
                    const navbarHeight = 85;
                    const elementPosition = element.offsetTop - navbarHeight;
                    window.scrollTo({
                        top: elementPosition,
                        behavior: 'smooth'
                    });
                }
            }, 0);
        }
    }, [location.pathname]);

    const handleLogoClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        if (location.hash) {
            scrollToHash(location.hash);
        }
    }, [location, scrollToHash]);

    const changeLanguage = (langCode) => {
        i18n.changeLanguage(langCode);
        localStorage.setItem('preferred-language', langCode);
        setIsLanguageOpen(false);
    };

    useEffect(() => {
        const savedLanguage = localStorage.getItem('preferred-language');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);

    // click outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isLanguageOpen && !event.target.closest('.language-dropdown')) {
                setIsLanguageOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isLanguageOpen]);

    const MenuLink = ({ item }) => {
        return (
            <Link
                to={item.path}
                onClick={() => scrollToHash(item.path.substring(1))}
                className="relative text-white lg:p-2 rounded-lg transition-all duration-300 ease-in-out
                transform hover:scale-105
                before:content-[''] before:absolute before:inset-0 before:rounded-lg 
                before:bg-gradient-to-r before:from-[#D21E2B] before:to-[#D27077]
                before:opacity-0 hover:before:opacity-100 before:transition-opacity before:duration-300
                hover:shadow-lg hover:shadow-[#D21E2B]/20
                overflow-hidden group"
            >
                <span className="relative z-10 group-hover:text-white">{item.title}</span>
            </Link>
        );
    };

    const LanguageMenu = () => (
        <div className="relative language-dropdown">
            <button
                onClick={() => setIsLanguageOpen(!isLanguageOpen)}
                className="inline-flex items-center gap-2 rounded-md bg-transparent border py-1.5 px-3 text-sm font-semibold text-white shadow-inner shadow-white/10 
                transition-all duration-300 ease-in-out
                hover:bg-gradient-to-r hover:from-[#D21E2B] hover:to-[#D27077]
                hover:border-transparent hover:scale-105 hover:shadow-lg hover:shadow-[#D21E2B]/20"
            >
                <Globe size={16} />
                {languages.find(lang => lang.code === i18n.language)?.name || t('Language')}
                <ChevronDown className={`size-4 transform transition-transform duration-300 ${isLanguageOpen ? 'rotate-180' : ''}`} />
            </button>

            {isLanguageOpen && (
                <div className="absolute right-0 mt-2 w-48 rounded-md bg-gray-800/95 backdrop-blur-sm shadow-lg ring-1 ring-black ring-opacity-5 z-50">
                    <div className="py-1">
                        {languages.map((language) => (
                            <button
                                key={language.code}
                                onClick={() => changeLanguage(language.code)}
                                className="text-gray-300 hover:bg-gradient-to-r hover:from-[#D21E2B] hover:to-[#D27077] hover:text-white
                                group flex w-full items-center px-4 py-2 text-sm transition-all duration-300"
                            >
                                {language.name}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <nav className="bg-[#FFFFFF0F] top-0 left-0 right-0 z-40 fixed">
            <div className="mx-auto px-3 bg-[#00000080]">
                <div className="flex lg:justify-around justify-between items-center h-[90px]">
                    <div className="flex items-center">
                        <Link
                            to="#"
                            className="flex items-center"
                            onClick={handleLogoClick}
                        >
                            <img src={Logo} alt="Logo" />
                        </Link>
                    </div>

                    <div className="hidden md:flex items-center space-x-8">
                        {menuItems.map((item) => (
                            <MenuLink key={item.id} item={item} />
                        ))}
                        <LanguageMenu />
                    </div>
                    <div className='md:hidden block'>
                        <LanguageMenu />
                    </div>

                    <div className="md:hidden flex items-center">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="text-gray-300 hover:text-white focus:outline-none"
                        >
                            {isOpen ? <X size={32} /> : <Menus size={32} className='text-white p-1 rounded bg-[#FFFFFF30]' />}
                        </button>
                    </div>
                </div>

                <div
                    className={`md:hidden overflow-hidden transition-all duration-300 ease-in-out bg-[#00000080]"> ${isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}
                >
                    <div className="border-t border-gray-700">
                        <div className="flex flex-col py-4">
                            {menuItems.map(item => (
                                <Link
                                    key={item.id}
                                    to={item.path}
                                    onClick={() => scrollToHash(item.path.substring(1))}
                                    className="block px-4 py-2 text-gray-300 hover:text-white transition duration-300"
                                >
                                    {item.title}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;