import React from 'react'
import { Outlet } from "react-router-dom"
import Navbar from './layouts/Navbar'
import Footer from './layouts/Footer'
import Hero from './Hero'

const Layout = () => {
    return (
        <>

            <div className='relative w-full'>
                <Navbar />
                <Hero />
            </div>

            <div className=" mx-auto bg-white" id='main' >
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default Layout