import React from 'react';
import { useForm } from 'react-hook-form';
import Banner from '../assets/images/img/contact-banner.png'
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <div className='contact-bg' id='contact-form'>
            <div className='flex lg:flex-row flex-col-reverse justify-center items-center gap-[55px] p-4 bg-gradient-to-b from-[#210404CC] to-[#871111CC] py-20'>
                <div className='lg:pt-20'>
                    <div>
                        <h3 className='text-white text-3xl font-semibold mb-10 lg:text-left text-center'>{t('contact.title')}</h3>
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 lg:px-0 px-5">
                            <div className='grid grid-cols-2 gap-4'>
                                <div>
                                    <input
                                        {...register("name", {
                                            required: t('validation.nameRequired')
                                        })}
                                        placeholder={t('contact.name')}
                                        type="text"
                                        className="block w-full px-3 py-2 bg-transparent border border-[#FFFFFF33] rounded-md shadow-sm focus:outline-none placeholder:text-[#FFFFFF99] text-white"
                                    />
                                    {errors.name && (
                                        <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
                                    )}
                                </div>
                                <div>
                                    <input
                                        {...register("surname", {
                                            required: t('validation.surnameRequired')
                                        })}
                                        placeholder={t('contact.surname')}
                                        type="text"
                                        className="block w-full px-3 py-2 bg-transparent border border-[#FFFFFF33] rounded-md shadow-sm focus:outline-none placeholder:text-[#FFFFFF99] text-white"
                                    />
                                    {errors.surname && (
                                        <p className="mt-1 text-sm text-red-600">{errors.surname.message}</p>
                                    )}
                                </div>
                            </div>

                            <div>
                                <input
                                    {...register("email", {
                                        required: t('validation.emailRequired'),
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: t('validation.emailInvalid')
                                        }
                                    })}
                                    placeholder={t('contact.email')}
                                    type="email"
                                    className="block w-full px-3 py-2 bg-transparent border border-[#FFFFFF33] rounded-md shadow-sm focus:outline-none placeholder:text-[#FFFFFF99] text-white"
                                />
                                {errors.email && (
                                    <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
                                )}
                            </div>

                            <div>
                                <input
                                    {...register("subject", {
                                        required: t('validation.subjectRequired')
                                    })}
                                    placeholder={t('contact.subject')}
                                    type="text"
                                    className="block w-full px-3 py-2 bg-transparent border border-[#FFFFFF33] rounded-md shadow-sm focus:outline-none placeholder:text-[#FFFFFF99] text-white"
                                />
                                {errors.subject && (
                                    <p className="mt-1 text-sm text-red-600">{errors.subject.message}</p>
                                )}
                            </div>

                            <div>
                                <textarea
                                    {...register("message", {
                                        required: t('validation.messageRequired')
                                    })}
                                    rows={4}
                                    placeholder={t('contact.message')}
                                    className="block w-full px-3 py-2 bg-transparent border border-[#FFFFFF33] rounded-md shadow-sm focus:outline-none placeholder:text-[#FFFFFF99] text-white"
                                />
                                {errors.message && (
                                    <p className="mt-1 text-sm text-red-600">{errors.message.message}</p>
                                )}
                            </div>

                            <button
                                type="submit"
                                className="w-full  px-6 py-2 border border-transparent rounded-[42px] shadow-sm text-sm font-medium text-black bg-white hover:bg-slate-200 focus:outline-none"
                            >
                                {t('contact.submit')}
                            </button>
                        </form>
                    </div>
                </div>
                <div>
                    {/* contact banner */}
                    <div className=''>
                        <img src={Banner} alt="" className='lg:w-[575px] lg:h-[536px] rounded-xl drop-shadow-redShadow' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;